@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');
body {
  background-color: white;
  margin: auto;
  display: flow-root;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'PT Serif', serif!important;
}

:root {
  --heading-color: #1c1c25;
  --bg-color: #f2faff;
  --bg-color-2: #F7FAFE;
  --body-color: #888888;
  --theme-color: #192FAB;
  --theme-color-2: #4777f4;
  --theme-color-3: #2024b0;
  --theme-color-sec: #255dff;
  --white-color: #fff;
  --black-color: #000;
  --light-color: #EFEFEF;
  --border-color:#E3E8FE;
  --lightgrey-color: #ccc;
  --font-family-1: 'Heebo', sans-serif;
    --font-family-2: 'Roboto', sans-serif;
  --font-family-3: 'Livvic', sans-serif;
  --font-family-4: 'Poppins', sans-serif;
  --font-family-5: 'Amatic SC', cursive;
  --image-color:linear-gradient( 90deg, rgb(14,140,209) 0%, rgb(25,47,171) 99%);
}

@media (min-width: 1200px){ 
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1200px;
  }
  }
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  float: left;
}
.clearfix{
  clear:both;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  text-align: center;
  background-color: white;
  min-height: 100vh;
  outline: 2px solid #ffffff;
  outline-offset: 2px;
  width: 100%;
  float: left;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
.is-active {
  color: #ff5800!important;
}
p{
  line-height: 1.8;
  color: #635d5d;
  font-family: 'PT Serif', serif!important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: #000;
  font-family: 'PT Serif', serif!important;
}
a{
  font-family: 'PT Serif', serif!important;
}
li{
  font-family: 'PT Serif', serif!important;
}
dl, ol, ul {
  padding: 0;
}
.App {
  text-align: center;
}
.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  position: relative;
  list-style: none;
  margin: 4px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 17px;
  font-weight: 400;
  line-height: 40px;
  position: relative;
  display: block;
  color: #000000;
  margin: 40px 10px;
}
.is-active {
  color: #0364f1!important;
}
/* ---------------------------------------------------------------------------------------------- */
.header-top {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: inherit;
  background-position: center center;
  background-color: rgb(0 68 251 / 89%);
  position: relative;
}
.header-top, .header-top span, .header-top p {
  color: #ffffff;
}
.header-top:before {
  background-color: rgb(0 68 251 / 89%);
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.header-top-inner p {
  display: inline-block;
  margin: 0;
  padding: 0 16px;
  line-height: 50px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.header-top-inner p svg {
  margin-right: 7px;
  font-size: 18px;
  vertical-align: -2px;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.dgt-social ul {
  list-style: none;
  margin: 0;
}
.dgt-social ul li {
  display: inline-block;
}
.header-top-inner .dgt-social ul li a {
  line-height: 50px;
  font-size: 18px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  min-width: 50px;
  text-align: center;
  display: block;
  padding: 0 10px;
  line-height: 50px;
}
.header-top a {
  color: #ffffff;
}
.dgt-social.name-hidden li span {
  display: none;
}
.header-top svg {
  width: 20px!important;
  height: 17px;
}
/* ---------------------------------------------------------------------------------------------- */
.banner {
  width: 100%;
}
.banners2 {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
}
/* 
.BrainhubCarouselItem--active img{
  -webkit-animation: zoomin 37s ease-in infinite;
  animation: zoomin 37s ease-in infinite;
  transition: all .5s ease-in-out;
  overflow: hidden;
  background-size: auto;
  background-attachment: fixed;
  background-repeat: repeat;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
} */
.banners2 h1 {
  color: #fff;
  font-size: 54px;
  text-align: center;
  display: block;
  position: relative;
  font-weight: 500;
  line-height: 70px;
  margin-bottom: 0;
}
.banners2 p {
  color: #fff;
  font-size: 23px;
  margin: 17px 0px 0px 0px;
}
.banners2 h6 {
  display: inline-block;
  font-size: 20px;
  margin: 27px 0 0 0;
  color: #656060;
  font-weight: bold;
  letter-spacing: 0.7px;
  width: 60px;
  height: 60px;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
  margin-top: 24px;
  color:white;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
/* ------------------------------------------------------------------------------------------ */
/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
  0% {transform: scale(1);}
  50% {transform: scale(1.5);}
  100% {transform: scale(1);}
}
@keyframes zoomin {
  0% {transform: scale(1);}
  50% {transform: scale(1.5);}
  100% {transform: scale(1);}
} /*End of Zoom in Keyframes */

/* Zoom out Keyframes */
@-webkit-keyframes zoomout {
  0% {transform: scale(1);}
  50% {transform: scale(0.67);}
  100% {transform: scale(1);}
}
@keyframes zoomout {
    0% {transform: scale(1);}
  50% {transform: scale(0.67);}
  100% {transform: scale(1);}
}/*End of Zoom out Keyframes */

/* ------------------------------------------------------------------------------------------ */
.Sectionone{
  width: 100%;
  float: left;
  position: relative;
  padding: 52px 0px 62px 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  overflow: hidden;
}
.Sectionone h1 p {
  text-align: unset;
  font-size: unset;
  line-height: unset;
  color: unset;
}
.p_absoulte {
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
}
.creative_about_img .creative_shap {
  border-radius: 10px;
  background: #7b2e0045;
  width: 140px;
  height: 160px;
  position: absolute;
  top: -30px;
  left: -30px;
  z-index:-1;
}
.contactformsmmm iframe {
  width: 100%;
  height: 400px;
}
.creative_about_img .dot {
  left: -30px;
  bottom: -42px;
  top: unset;
  float: none;
  z-index: -1;
  box-shadow:none;
}
.creative_about_img {
  position: relative;
  margin-left: 80px;
  z-index: 2;
}
.creative_about_img img{
  max-width: 90%;
  box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 2;
}
.Sectionone h1 {
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 62px;
}
.buttonlink:hover {
  color: #ffffff;
  text-decoration: underline;
}
.Sectionone h1 span {
  color: #01aafc!important;
}
.Sectionone p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
}
.Sectionone li {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  color: #635d5d;
  list-style-type: disc;
  margin-left: 47px;
  margin-bottom: 17px;
}
::marker {
  color: #01aafc;
}
.buttonlink {
  font-size: 18px;
  font-weight: normal;
  padding: 12px 40px 12px 40px;
  margin: 20px 0 0 0;
  color: #fff;
  background: #0040fa;
  background: -moz-linear-gradient(top, #95541e 0%, #b56829 100%);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#0147fb), to(#01ccfc));
  border: 1px solid #0185fc;
  border-bottom: 3px solid #01aefc;
  box-shadow: inset 0 1px 0 0 #08b6f3;
  text-shadow: 0 -1px 0 #02a2f4;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  float: left;
}
.buttonlink svg {
  width: 13px;
  height: 17px;
  margin-right: 7px;
}
.pegion {
  position: absolute;
  right: -54px;
  top: 127px;
  z-index: 0;
  width: 507px;
  opacity: 0.4;
}
/* ------------------------------------------------------------------------------------------ */
.sectiontwo{
  background-color: #f7f4ef;
  width: 100%;
  float: left;
  position: relative;
  padding: 52px 0px 0px 0px;
}
.sectiontwo h1 {
  font-size: 53px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
}
.sectiontwo h6 {
  margin-bottom: 42px;
  font-size: 18px;
  letter-spacing: 0.7px;
  color: #6f6767;
}
.sectiontwo h1 span {
  color: #01aafc;
}
.sectiontwo p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
}
.buttonlink7 {
  position: relative;
  font-size: 23px;
  margin: 0 auto 62px;
  font-weight: 400;
  padding: 10px 30px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  color: #555;
  background-color: #ffffff;
  border: 0px;
  box-shadow: -4px -4px 10px 0px #ffffff, -4px -4px 5px 0px #ffffff, 4px 4px 10px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #ffffff, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #ffffff, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
}
.buttonlink7:hover {
  box-shadow: 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #ffffff, inset -4px -4px 5px 0px #ffffff, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  color: #803304;
  text-decoration: none;
}
.buttonlink72 {
  position: relative;
  font-size: 23px;
  margin: 0 auto 62px;
  font-weight: 400;
  padding: 10px 30px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  color: #555;
  background-color: #f7f4ef;
  border: 0px;
  box-shadow: -4px -4px 10px 0px #f7f4ef, -4px -4px 5px 0px #f7f4ef, 4px 4px 10px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #f7f4ef, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #f7f4ef, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
}
.buttonlink72:hover {
  box-shadow: 0px 0px 0px 0px #f7f4ef, 0px 0px 0px 0px #f7f4ef, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #e4dbcc, inset -4px -4px 5px 0px #f7f4ef, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  color: #803304;
  text-decoration: none;
}
img.servicesss {
  width: 170px;
  height: 170px;
  object-fit: cover;
  object-position: center;
  border: 4px solid white;
  box-shadow: 0px 1px 17px #a7a099;
  z-index: 0;
  margin-bottom: -60px;
  border-top-left-radius: 37%;
  border-bottom-right-radius: 37%;
  
}
.sectiontwo h3 {
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 27px;
}
.servicesss2 {
  position: relative;
  z-index: 1;
}
.loadersss {
  width: 300px;
  height: 77px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  margin-top: -40px;
  display: block;
  text-align: center;
  font-size: 47px;
  font-weight: 600;
  vertical-align: middle;
  color: #01aafc;
}
/* --------------------------------------------------------------------------------------------- */
.sectionthree {
  width: 100%;
  float: left;
  position: relative;
  padding: 52px 0px 52px 0px;
}
.sectionthree h1 {
  font-size: 47px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 33px;
}
.sectionthree h1 span {
  color: #01aafc;
}
.sectionthree p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
}
.dgt-team-item-inner .dgt-team-avatar img {
  border-radius: 3%;
  max-width: 247px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  -khtml-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  margin-top: -93px;
}
.sectionthree a {
  color: #a5a5a5;
  text-decoration: none;
  background-color: transparent;
}
.sectionthree h4{
  font-size: 27px;
  margin: 27px 0px 23px 0px;
  letter-spacing: 0.7px;
  color: #534f4f;
}
h6{
  padding-bottom: 17px;
}
.sectionthree {
  color: #767474;
  font-family: 'PT Serif', serif;
  font-size: 17px;
}
.sectionthree .dgt-team-item-inner .dgt-social {
  padding: 24px 0 0;
  margin: 0 80px;
  border-top: 1px solid #e5e5e5;
}
.sectionthree .dgt-social ul {
  list-style: none;
  margin: 0;
}
.sectionthree .dgt-social ul li {
  display: inline-block;
  line-height: normal;
  font-size: 16px;
  color: #666666;
  padding: 0 10px;
  line-height: 50px;
}
.sectionthree .dgt-team-item-inner .dgt-blog-readmore a {
  padding: 17px 0 0;
  display: block;
  text-transform: uppercase;
  color: #232323;
  font-size: 16px;
}
.sectionthree .dgt-team-item-inner {
  padding: 17px 17px 37px 17px;
}
.sectionthree .dgt-team-item-inner:hover {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid transparent;
    border-color: #01aafc;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -khtml-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}
/* --------------------------------------------------------------------------------------------- */
.sectionfour {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  background-color: #31343a;
  float: left;
  position: relative;
}
.sectionfour .col-md-6{
  padding: 0px;
}
.swmsc_half_bg_container {
  display: block;
  margin: 0;
  padding: 0;
  background-position-x: -227px;
}
.swmsc_half_bg {
  background-size: cover;
  background-position: center;
}
.sectionfour h1 {
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 30px;
  text-align: left;
  line-height: 67px;
}
.sectionfour p{
  color: white;
  font-size: 17px;
  padding: 0px 37px 0px 37px;
  letter-spacing: 0.7px;
  line-height: 37px;
  text-align: left;
}
.swmsc_half_bg2 {
  padding: 62px 130px 62px 77px;
}
.swmsc_half_bg2sss{
  opacity: 0;
}
/* --------------------------------------------------------------------------------------------- */
.sectionfive{
  width: 100%;
  float: left;
  position: relative;
  padding: 60px 0px 60px 0px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.sectionfive h1 {
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 42px;
  text-align: left;
}
.sectionfive h1 span {
  color: #01aafc;
}
.sectionfive p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
}
.sectionfive img {
  max-height: 343px;
  border-radius: 30px;
  margin-top: 10px;
}
.sectionfive2 {
  margin-top: 52px;
}
.sectionfive3 {
  height: 277px;
  overflow: hidden;
}
/* --------------------------------------------------------------------------------------------- */
.sectionsix{
  background-color: #f7f4ef;
  width: 100%;
  float: left;
  position: relative;
  padding: 77px 0px 77px 0px;
}
.sectionsix .col-md-4{
  padding-right: 0px;
  margin-bottom: 15px;
}
.sectionsix img{
  width: 385px;
  height: 277px;
  object-fit: cover;
  object-position: center;
}
.sectionsix h1 {
  font-size: 53px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
}
.sectionsix h6 {
  margin-bottom: 77px;
  font-size: 18px;
  letter-spacing: 0.7px;
  color: #6f6767;
}
.sectionsix h1 span {
  color: #01aafc;
}
.sectionsix p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
}
/* --------------------------------------------------------------------------------------------- */
.sectionseven {
  width: 100%;
  float: left;
  position: relative;
  padding: 0px 0px 46px 0px;
}
.sectionseven h1 {
  font-size: 53px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
}
.sectionseven h1 span {
  color: #01aafc;
}
.sectionseven h3 {
  font-size: 22px;
  letter-spacing: 0.7px;
  text-align: left;
  line-height: 37px;
  margin-bottom: 17px;
}
.sectionseven3 {
  margin-bottom: 17px;
  border: 1px solid #dadada;
  padding: 27px;
  width: 100%;
  float: left;
  overflow: hidden;
}

.sectionseven p.sectionseven2 {
  text-align: left;
  font-size: 17px;
  line-height: 33px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 200px;
}
.sectionseven2s{
  text-align: left;
  font-size: 17px;
  color: #01aafc;
  margin-bottom: 17px;
}
.sectionseven img{
  width: 385px;
  height: 277px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 37px;
}
.sectionseven .dgt-blog-readmore a {
  padding: 17px 0 0;
  display: block;
  text-transform: uppercase;
  color: #232323;
  font-size: 16px;
}
/* --------------------------------------------------------------------------------------------- */
.sectioneight {
  background-color: #f2f2f2;
  background-size: auto;
  width: 100%;
  float: left;
  padding: 52px 0px 52px 0px;
  position: relative;
}
.sectioneight H1 {
  font-size: 47px;
  font-weight: 600;
  letter-spacing: 0.7px;
}
.sectioneight h1 span {
  color: #01aafc;
}
.sectioneight:before {
  position: absolute;
  left: 50%;
  margin-left: -15px;
  height: 30px;
  width: 30px;
  z-index: 3;
  background-color: #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  border-left: 1px;
  content: "";
  bottom: -16px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
/* --------------------------------------------------------------------------------------------- */
.sectioneighttwo {
  width: 100%;
  float: left;
  padding: 77px 0px 0px 0px;
  position: relative;
  overflow: hidden;
  background-image: url(https://npcentre.ca/uploads/sea-2672761_960_720.png),linear-gradient(90deg,rgb(255 255 255 / 78%),hsl(0deg 0% 100% / 74%) 50%);
    background-blend-mode: soft-light;
    background-color: initial;
    background-repeat: repeat;
    background-size: auto;
    background-blend-mode: soft-light;
}
.sectioneighttwo iframe#ytplayer {
  width: 93%;
  height: 257px;
  margin: 0px auto 17px;
  display: block;
  position: relative;
  box-shadow: 7px 7px 17px -15px, 0 0 7px -17px;
  color: #000;
  border-radius: 17px;
  border: 7px solid #fff;
}
.sectioneighttwo H1 {
  font-weight: 500;
  letter-spacing: 0.7px;
  text-align: left;
  font-size: 22px;
  margin-bottom: 17px;
  line-height: 43px;
  color: #000000;
}
.sectioneighttwo p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  height: 77px;
  overflow: hidden;
  margin-bottom: 0;
}
.sectioneighttwosss {
  margin-bottom: 43px;
}
.p_absoulte2 {
  position: absolute;
  z-index: 0;
  right: -307px;
  top: 5%;
  opacity: 0.1;
}
/* --------------------------------------------------------------------------------------------- */
.sectionnine {
  width: 100%;
  float: left;
  padding: 42px 0 52px;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.sectionnine H1 {
  font-weight: 600;
  letter-spacing: 0.7px;
  text-align: left;
  font-size: 42px;
  margin-bottom: 27px;
  line-height: 67px;
}
.sectionnine h1 span {
  color: #01aafc;
}
.sectionnine p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
}
/* --------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------ */
div#Footer {
  position: relative;
  width: 100%;
  float: left;
  padding: 60px 0px 60px 0px;
  display: block;
  /* background: linear-gradient(to right,#757575 0%,#2d2d2d 25%,#31343a 75%,#31343a 100%); */
  background-color: #fffcf3;
}
div#Footer h1 {
  color: #01aafc; 
  font-size: 37px;
  letter-spacing: 0.7px;
  margin-bottom: 47px;
  text-align: center;
  font-weight: 600;
}
.menu-itemfooter {
  display: inline-block;
  margin: 0px 0px 17px 0px;
  font-size: 17px;
  text-align: center;
  float: left;
}
.menu-itemfooter a{
  color: #000000;
}
.textfooter{
  font-size: 17px;
  text-align: left;
}
.textfooter a{
  color: #CDCDCD;
}
.textfooter span{
  color: #CDCDCD;
}
.textfooter svg {
  width: 1em;
  color: #01aafc;
  margin-right: 10px;
  font-size: 20px;
}
.footersss2 {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: inherit;
    background-position: center center;
    background-color: rgb(2 69 252 / 92%);
    position: relative;
    padding: 17px;
}
.footersss2:before {
  content: "";
  position: absolute;
  width:100%;
  height:100%;
  background-color: rgb(2 69 252 / 92%);
  left: 0;
  top: 0;
}
.footersss2 p {
  color: #CDCDCD;
  font-size: 17px;
  text-align: right;
  margin-bottom: 0;
}
.footersss2 a {
  color: #CDCDCD;
  letter-spacing: 1px;
}
.sectionnine img{
  margin-top: 80px;
}
/* --------------------------------------------------------------------------------------------- */
.scrolls {
  display: inline-block;
  width: 45px;
  height: 45px;
  position: fixed;
  bottom: 40px;
  right: 7px;
  z-index: 99;
  outline: none;
  background-size: auto 200%;
  border-radius: 50%;
  transition: all .4s ease;
  background-image: linear-gradient(180deg,#0147fb,#0147fb 51%,#1f96e0);
  vertical-align: middle;
  text-align: center;
  line-height: 47px;
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#0147fb), to(#01ccfc));
  border: 1px solid #0185fc;
  border-bottom: 3px solid #01aefc;
  box-shadow: inset 0 1px 0 0 #08b6f3;
  text-shadow: 0 -1px 0 #02a2f4;
  cursor: pointer;
}
.scrolls svg {
  color: white;
  font-size: 23px;
}
/* --------------------------------------------------------------------------------------------- */
.events {
  background-color: white;
  width: 100%;
  float: left;
  padding: 37px 27px 37px 27px;
  margin-bottom: 37px;
  background-image: url(https://npcentre.ca/uploads/light-blue-square-mosaic-tiles-background-vector-22519202.jpg),linear-gradient(90deg,rgb(255 255 255 / 76%),hsl(0deg 0% 100% / 76%) 50%);
    background-blend-mode: soft-light;
    background-color: initial;
    background-repeat: repeat;
    background-size: auto;
    background-blend-mode: soft-light;
}
.events h2{
  text-align: left;
  font-size: 22px;
  margin-bottom: 27px;
}
.events img.attachment-full.size-full {
  width: 257px;
  height: 257px;
  object-fit: cover;
  object-position: center;
}
.events img.attachmentsss {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center top;
}
.block-ellipsis {
  height: 67px;
  overflow: hidden;
  margin-bottom: 10px;
}
.sectiontwo .block-ellipsis p {
  text-align: left;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  font-size: 16px;
  line-height: 27px;
  margin: 0 0 7px 0;
}
.sectiontwo .block-ellipsis2 p {
  text-align: left;
  font-size: 16px;
  line-height: 27px;
  margin: 0 0 7px 0;
}
.sectiontwo .block-ellipsis2 svg{
  color: #01aafc;
  margin-right: 7px;
}
.sectiontwo .block-ellipsis2 .dgt-blog-readmore a {
  padding: 17px 0 0;
  display: block;
  text-transform: capitalize;
  color: #01aafc;
  font-size: 17px;
  text-align: left;
}
.sectiontwo p {
  text-align: center;
  font-size: 18px;
  line-height: 37px;
}
.sectiontwo .sectiontwoqqq {
  margin-bottom: 0px;
  font-size: 20px;
  letter-spacing: 0.7px;
  color: #01aafc;
  margin-top: 20px;
  font-weight: 600;
  padding: 0;
}
/* --------------------------------------------------------------------------------------------- */
.sectiontwo7 {
  background-color: #f7f4ef;
  width: 100%;
  float: left;
  position: relative;
  padding: 52px 0px 0px 0px;
  transition: all .4s ease-in-out;
}
.sectiontwo7 h3{
  top: 50%;
  position: absolute;
  opacity: 0;
  transition: all .4s ease-in-out;
}
.sectiontwo7 .servicesss2:hover h3{
  top: 0%;
  position: absolute;
  opacity: 1;
  transition: all .4s ease-in-out;
  left: 0;
  right: 0;
  font-size: 23px;
  font-weight: 600;
  color: #01aafc;
}
.sectiontwo7 .servicesss2:hover img.servicesss {
  transition: all .4s ease-in-out;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 60px;
}
.sectiontwo7 h1 {
  font-size: 53px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
}
.sectiontwo7 h6 {
  margin-bottom: 37px;
  font-size: 18px;
  letter-spacing: 0.7px;
  color: #6f6767;
}
.sectiontwo7 h1 span {
  color: #01aafc;
}
/* --------------------------------------------------------------------------------------------- */
.breadcrumps{
  background-position: center top;
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: cover;
    padding-top: 105px;
    padding-bottom: 97px;
}
.breadcrumps h1{
    color: #fff;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    letter-spacing: 0.7px;
}
.godgrace_breadcrumbs, .godgrace_breadcrumbs a {
  color: #ffffff;
  font-size: 15px;
  text-transform: none;
}
.godgrace_breadcrumbs {
  padding: 5px 12px;
  display: inline-block;
  position: relative;
  text-align: center;
  z-index: 2;
  border: 2px solid rgba(255,255,255,0.15);
  line-height: normal;
}
.godgrace_breadcrumbs svg {
  height: 13px;
}
.godgrace_breadcrumbs a {
  text-decoration: none;
}
.godgrace_breadcrumbs span.godgrace_bc_sep {
  width: 20px;
  text-align: center;
  padding: 0 7px;
}
.clear, .clearfix, .clearall {
  clear: both;
}
/* --------------------------------------------------------------------------------------------- */
.aboutpages{
  width: 100%;
  float: left;
  position: relative;
  padding: 54px 0px 36px 0px;
  background-color: #f7f4ef;
}
.aboutpagesqqq h1, .aboutpagesqqq h2, .aboutpagesqqq h3, .aboutpagesqqq h4, .aboutpagesqqq h5, .aboutpagesqqq h6 {
  font-size: 53px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  line-height: 60px;
  
}
.aboutpagesqqq p, .aboutpagesqqq h2 p, .aboutpagesqqq h3 p, .aboutpagesqqq h4 p, .aboutpagesqqq h5 p, .aboutpagesqqq h6 p {
  line-height: 60px!important;
}

.aboutpagesqqq h1 span, .aboutpagesqqq h2 span, .aboutpagesqqq h3 span, .aboutpagesqqq h4 span, .aboutpagesqqq h5 span, .aboutpagesqqq h6 span {
  color: #01aafc;
}
.aboutpages p {
  text-align: center;
  font-size: 18px;
  line-height: 37px;
}
.aboutpagesqqq2 p, .aboutpagesqqq2 h1, .aboutpagesqqq2 h2, .aboutpagesqqq2 h3, .aboutpagesqqq2 h4, .aboutpagesqqq2 h5, .aboutpagesqqq2 h6{
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
  text-align: center!important;
  font-size: 18px!important;
  line-height: 37px;
}
.aboutpagesqqq2 p  span, .aboutpagesqqq2 h1  span, .aboutpagesqqq2 h2  span, .aboutpagesqqq2 h3  span, .aboutpagesqqq2 h4  span, .aboutpagesqqq2 h5  span, .aboutpagesqqq2 h6  span{
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
  text-align: center!important;
  font-size: 18px!important;
  line-height: 37px;
}
/* --------------------------------------------------------------------------------------------- */
.aboutpages2{
  width: 100%;
  float: left;
  position: relative;
  padding: 60px 0px 60px 0px;
}
.aboutpages2 h1 {
  font-size: 53px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
}
.aboutpages2 h1 span {
  color: #01aafc;
}
.aboutpages2 p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
}
.aboutpages2 h6 {
  padding-bottom: 7px;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}
.aboutpages2 b {
  color: #01aafc;
  font-size: 23px;
  border-bottom: 2px solid;
}
.aboutpages2qqq {
  position: relative;
  width: 100%;
  float: left;
  padding: 47px 47px 40px 47px;
  border: 1px solid lavender;
  border-radius: 27px;
}
/* --------------------------------------------------------------------------------------------- */
.aboutpages3 {
  width: 100%;
  float: left;
  position: relative;
  padding: 54px 0px 36px 0px;
  background-position: center top;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
}
.aboutpages3 h1 {
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  text-align: center;
  color: #ffffff;
  line-height: 60px;
}
.aboutpages3 h1 span {
  color: #ffffff;
}
.aboutpages3 p {
  text-align: center;
  font-size: 18px;
  line-height: 37px;
  color: #ffffff;
}
/* --------------------------------------------------------------------------------------------- */
.aboutpages4{
  width: 100%;
  float: left;
  position: relative;
  padding: 54px 0px 36px 0px;
}
.aboutpages4 h1 {
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
}
.aboutpages4 h1 span {
  color: #01aafc;
}
.aboutpages4 p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
}
.aboutpages4 img{
  margin-top: 107px;
}
/* --------------------------------------------------------------------------------------------- */
.ourteampages{
  width: 100%;
  float: left;
  position: relative;
  padding: 77px 0px 77px 0px;
}
/* --------------------------------------------------------------------------------------------- */
.prayerreqsss {
  width: 100%;
  float: left;
  position: relative;
  padding: 60px 0px 60px 0px;
  background-color: #f7f4ef;
}
.prayerreqsss form.login-forms {
  background-color: white;
  border: 1px solid lavender;
  padding: 52px;
  border-radius: 7px;
}
.prayerreqsss h5{
  color: #0142fb;
  margin-bottom: 27px;
  text-align: left;
}
.adverts-form-required{
  color: #01aafc;
}
.font-weight-bolds {
  text-align: left;
  float: left;
  color: #4e4949;
  width: 100%;
  margin: 10px 0px 7px 0px;
  letter-spacing: 0.7px;
  font-size: 17px;
}
label{
  font-family: 'PT Serif', serif;
}
.form-control {
  height: 57px;
  font-family: 'PT Serif', serif;
}
/* --------------------------------------------------------------------------------------------- */
.contactforms{
  width: 100%;
  float: left;
  position: relative;
  padding: 20px 0px 42px 0px;
}
.contactforms h1 {
  font-size: 53px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
}
.contactforms h1 span {
  color: #01aafc;
}
.contactforms iframe{
  width: 100%;
  height: 400px;
}
.contactforms2{
  margin: 42px 0px 0px 0px;
}
.dgt-sub-title {
  margin: 17px 0px 60px 0px;
  font-size: 20px;
  line-height: 27px;
  color: #6e6f6e;
}
.contacticonsss {
  color: #01aafc;
  font-size: 57px;
  margin-bottom: 37px;
  margin-top: 27px;
}
.contactforms2 h3{
  margin-bottom: 27px;
  font-size: 30px;
  color: #474a47;
}
.contactforms2 ul li {
  display: inline-block;
}
.contactforms2 ul li a {
  padding: 0 30px 0 0;
  line-height: normal;
  font-size: 18px;
  color: #6a6a6a;
}
.contactforms2 p {
  font-size: 18px;
  line-height: 37px;
  margin-bottom: 7PX;
}
.dgt-contact-info {
  text-align: center;
  position: relative;
  padding: 0 60px;
}
.dgt-contact-info:not(.last):after {
  position: absolute;
  content: "";
  width: 1px;
  height: 50%;
  max-height: 100px;
  background-color: #e5e5e5;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -khtml-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
/* --------------------------------------------------------------------------------------------- */
.previewevents h2 {
  font-size: 27px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 60px;
  text-align: center;
  margin-top: 37px;
  color: #01aafc; 
}
.previewevents p {
    font-size: 18px;
    line-height: 37px;
}
.previewevents2 {
  background-color: #f7f4ef;
  padding: 60px 37px 32px 37px;
  margin-bottom: 60px;
}
.blogssspreviesss .sectionseven3 {
  padding: 17px 27px 37px 27px;
}
.events img.attachment-full.size-fullwww {
    width: auto;
    max-height: 397px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 60px;
}
.previewevents.sectiontwo .block-ellipsis2 p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  margin: 0 0 20px 0;
  font-weight: 600;
  color: black;
  letter-spacing: 1px;
}
/* --------------------------------------------------------------------------------------------- */
.blogsss{
  width: 100%;
  float: left;
  position: relative;
  padding: 60px 0px 43px 0px;
}
.blogsss h3 {
  font-size: 22px;
  letter-spacing: 0.7px;
  text-align: left;
  line-height: 37px;
  margin-bottom: 17px;
  height: 40px;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.blogsss img {
  width: 385px;
  height: 277px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 37px;
}
.blogsss p.sectionseven2 {
  text-align: left;
  font-size: 17px;
  line-height: 33px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 200px;
}
.blogssspreviesss.blogsss img {
  width: auto;
  height: auto;
  max-height: 397px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 60px;
}
.blogssspreviesss.blogsss h3 {
  font-size: 27px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 17px;
  text-align: center;
  margin-top: 37px;
  color: #01aafc;
}
.blogssspreviesss.blogsss .sectionseven2s {
  text-align: center;
  font-size: 17px;
  color: #01aafc;
  margin-bottom: 52px;
}
.blogssspreviesss.blogsss p.sectionseven2 {
  text-align: left;
  font-size: 17px;
  line-height: 33px;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  overflow: visible;
  text-overflow: unset;
  display: block;
  height: auto;
}
/* --------------------------------------------------------------------------------------------- */
.videos{
  width: 100%;
  float: left;
  position: relative;
  padding: 52px 0px 7px 0px;
}
.videos iframe#ytplayer {
  width: 93%;
  height: 257px;
  margin: 0px auto 17px;
  display: block;
  position: relative;
  box-shadow: 7px 7px 17px -15px, 0 0 7px -17px;
  color: #000;
  border-radius: 17px;
  border: 7px solid #fff;
}
.videos H1 {
  font-weight: 500;
  letter-spacing: 0.7px;
  text-align: left;
  font-size: 22px;
  margin-bottom: 17px;
  line-height: 43px;
  color: #000000;
}
.videos p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  height: 77px;
  overflow: hidden;
  margin-bottom: 0;
}
.videospreviews iframe#ytplayer {
  width: 100%;
  height: 607px;
  margin: 0px auto 52px;
  display: block;
  position: relative;
  box-shadow: 7px 7px 17px -15px, 0 0 7px -17px;
  color: #000;
  border-radius: 17px;
  border: 7px solid #fff;
}
.videospreviews.videos p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  height: auto;
  overflow: visible;
  margin-bottom: 0;
}
/* --------------------------------------------------------------------------------------------- */
.gallerysss{
  width: 100%;
  float: left;
  position: relative;
  padding: 60px 0px 36px 0px;
  cursor: pointer;
}
.gallerysss img {
  border: 7px solid lavender;
  transition: all .5s ease-in-out;
  height: 237px;
  object-fit: cover;
  margin-bottom: 27px;
  
}
.gallerysss2 img{
  width: 237px;
}
.gallerysss2 {
  position: relative;
  margin-bottom: 60px;
}
.gallerysss2:hover img{
  border-radius: 100%;
  transition: all .5s ease-in-out;
}
.gallerysss h3 {
  margin-bottom: 37px;
  transition: all .5s ease-in-out;
}
.gallerysss2 h3 {
  display: none;
}
.gallerysss2:hover h3 {
  color: #01aafc;
  font-weight: 700;
  position: absolute;
  z-index: 7;
  top: 37%;
  left: 0;
  right: 0;
  text-shadow: 1px 3px 5px white;
  font-size: 37px;
  transition: all .5s ease-in-out;
  display: block;
}
/* --------------------------------------------------------------------------------------------- */
.popup-content {
  top: 10%!important;
  left: 0%!important;
  right: 0%;
}
.feedspopups {
  background: rgba(0,0,0,.34901960784313724);
  box-shadow: 0 2px 22px 3px rgba(157,152,152,.21);
  padding: 10px 47px;
  position: fixed;
  top: 0;
  bottom: 0;
  max-width: 1027px;
  min-width: 637px;
  left: 0;
  right: 0;
   margin: 0 auto;
}
.popup-overlay {
  background: #01aafc;
}
.close {
  color: #fff!important;
  box-shadow: 0 1px 3px 0 rgb(29 161 243/58%);
  color: #fff;
  transition: all .75s ease;
  background-image: linear-gradient(90deg,#01aafc,#01aafc 50%,#01aafc);
  border-radius: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  opacity: 1;
  text-align: center;
  cursor: pointer;
}
.feeds {
  position: relative;
  width: 100%;
  float: left;
  border-top: 0px solid #e7edf2;
  margin: 17px 0 0;
  background-color: #fefefe;
  padding: 13px;
  box-shadow: inset 0px 1px 2px 7px #01aafc;
}

/* --------------------------------------------------------------------------------------------- */

.searchbar {
  width: 100%;
  float: left;
  position: relative;
  padding: 60px 0px 60px 0px;
  cursor: pointer;
  border-top: 1px solid #01aafc;
}
.searchlistsss{
  width: 100%;
  float: left;
  position: relative;
  padding: 60px 0px 60px 0px;
  cursor: pointer;
}
.searchbar input {
  width: 100%;
  height: 67px;
  border-radius: 0;
  box-shadow: 0 0 1rem rgb(29 161 243/17%);
  padding: 1rem;
  outline: none;
  border: none;
  border-bottom: 1px solid #01aafc;
}
.searchbar .sectionseven3 img {
  max-height: 197px;
  margin-bottom: 27px;
}
.searchbar .sectionseven3 {
  padding: 17px;
}
.searchbar .sectionseven3 h3{
  font-size: 18px;
  margin-bottom: 17px;
}
.searchbar .sectionseven2s {
  text-align: center;
  font-size: 16px;
  margin-bottom: 0px;
}
.searchbar .sectionseven3 .buttonlink {
  padding: 7px 17px 7px 17px;
  float: none;
}
.searchbar .events {
  background-color: #f7f4ef;
  padding: 27px 17px 27px 17px;
}
.searchbar .events h2 {
  font-size: 18px;
  line-height: 30px;
}
.searchbar .block-ellipsis2 p {
  text-align: left;
  font-size: 16px;
  line-height: 27px;
  margin: 0 0 7px 0;
}
.searchbar .block-ellipsis2 svg {
  color: #01aafc;
  margin-right: 7px;
}
.searchbar .block-ellipsis2 .dgt-blog-readmore a {
  padding: 17px 0 0;
  display: block;
  text-transform: capitalize;
  color: #01aafc;
  font-size: 17px;
  text-align: left;
}
.searchheadingsss {
  width: 100%;
  float: left;
  position: relative;
}
.searchheadingsss h1 {
  font-size: 37px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #01aafc;
  text-align: left;
}
.searchheadingsss2 {
  padding: 27px 0px 0px 0px;
  border-top: 1px solid #01aafc;
  margin-top: 60px;
}
.searchbar .sectioneighttwosss h1{
  font-size: 18px;
  margin-top: 17px;
  line-height: 37px;
}
.searchbar iframe{
 width: 87%;
  height: 237px;
  border: 7px solid #f7f4ef;
  box-shadow: 2px 3px 4px #01aafc;
}
.searchbar .buttonlink {
  padding: 7px 17px 7px 17px;
  margin: 7px 0 0 0;
  float: none;
}
.searchbar .searchheadingsss3 {
  float: left;
  border: 1px solid #01aafc;
  padding: 27px;
  width: 100%;
}
.searchbar .searchheadingsss3 a {
  color: #01aafc;
}
.buttonlinksearsss {
  font-size: 18px;
  font-weight: normal;
  padding: 10px 37px 10px 37px;
  margin: 20px 0 0 0;
  color: #ffffff;
  background: #131212;
  background: -moz-linear-gradient(top, #95541e 0%, #b56829 100%);
  background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(var(--green)), to(var(--green)));
  border: 0px solid #693516ab;
  border-bottom: 0px solid #7441168c;
  box-shadow: inset 0 1px 0 0 #28a745;
  text-shadow: 0 -1px 0 #28a745;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}
/* --------------------------------------------------------------------------------------------- */
.widget-flickr img {
  border-radius: 10px;
  width: 137px;
  height: 117px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
  margin-bottom: 1px;
  cursor: pointer;
}
.login-forms .react-tel-input .form-control {
  height: 60px;
  width: 100%;
}
.login-forms .react-tel-input .country-list .country {
  text-align: left;
}
.widget-flickr2 .col-md-3 {
  padding: 0 2px 4px 2px;
}
.previewevents .events {
  padding: 20px 27px 37px 27px;
  margin-top: 10px;
}
.previewevents.sectiontwo {
  padding: 52px 0px 27px 0px;
}
.sectiontwo.resss2  {padding: 52px 0px 36px 0px;}
/* --------------------------------------------------------------------------------------------- */
.watchsss {
  margin-right: 17px;
  font-size: 18px;
  text-align: center;
  padding: 0 10px;
  line-height: 50px;
}
ul.submenuss {
  background-color: white;
  box-shadow: 0px 2px 7px #6e2a007a;
  position: absolute;
  width: 232px;
  left: 0;
  display: none;
}
.menu-item{
  position: relative;
}

.menu-item ul.submenuss a {
  font-size: 17px;
  font-weight: 400;
  line-height: 40px;
  position: relative;
  display: block;
  color: #000000;
  margin: 7px 0px 0px 0px;
  border-bottom: 1px solid lavender;
  text-align: left;
  padding-left: 27px;
  padding-bottom: 7px;
}
.servicespage {
  width: 100%;
  float: left;
  position: relative;
  padding: 0px 0px 54px 0px;
  min-height: 67vh;
}
.servicespage h1 {
  font-size: 47px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
}
.servicespage p {
  text-align: center;
  font-size: 18px;
  line-height: 37px;
  text-align: left;
}
.servicespagelisttext2 p{
  text-align: center;
}
.header-top-inner .dgt-social ul li a.qqqmsss {
  line-height: 50px;
  font-size: 18px;
  border-left: 0px solid rgba(255, 255, 255, 0.3);
  min-width: auto;
  text-align: unset;
  display: block;
  padding: 0;
  line-height: 50px;
}
.mbs{
  margin-bottom: 27px;
}
.mbs p{
  text-align: center;
}
.mbs2{
  margin-top: 47px;
}
.react-tel-input {
  margin-bottom: 17px!important;
}
.Sectionone p.Adminbuttonlink2mmm {
  text-align: center;
  font-size: 22px;
  line-height: 37px;
  color: var(--green)!important;
  margin-bottom: 37px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.Sectionone p.Adminbuttonlink2mmm2 {
  text-align: center;
  font-size: 22px;
  line-height: 37px;
  color: #ff0000!important;
  margin-bottom: 37px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.Adminbuttonlink2mmm {
  text-align: center!important;
  font-size: 22px;
  line-height: 37px;
  color: var(--green)!important;
  margin-bottom: 37px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.Adminbuttonlink2mmm2 {
  text-align: center!important;
  font-size: 22px;
  line-height: 37px;
  color: #ff0000!important;
  margin-bottom: 37px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.admindisablsss{
  filter: blur(7px);
}
.admindisablsss2{
  opacity: 0.5;
  cursor: not-allowed!important;
}
.sectionnine H1 p{
  font-weight: 600;
  letter-spacing: 0.7px;
  text-align: left;
  font-size: 42px;
  margin-bottom: 27px;
  line-height: 67px;
  color: #000;
}
.sectionnine H1 p span{
  background-color: rgb(255 255 255 / 0%);
  color: #01aafc!important;
}
.aboutpages h1 p{
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000;
  line-height: 60px!important;
}
.aboutpages h1 p span {
  color: #01aafc!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 43px!important;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  line-height: 60px!important;
}
.aboutpages2 h1 p{
  font-size: 43px;
  font-weight: 700;
  letter-spacing: .7px;
  margin-bottom: 27px;
  color: #000;
  text-align: center;
  
  line-height: 60px!important;
}
.sectionfive h1 p{
  font-size: 42px;
  font-weight: 600;
  letter-spacing: .7px;
  margin-bottom: 42px;
  text-align: left;
  color: #000;
  line-height: 60px!important;
}
.aboutpages3 h1 p{
  font-size: 43px;
  font-weight: 700;
  letter-spacing: .7px;
  margin-bottom: 27px;
  
  line-height: 60px!important;
}
.aboutpages4 h1 p{
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000;
  line-height: 60px!important;
}
.aboutpages4 h1 p span {
  color: #01aafc!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 43px!important;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  line-height: 60px!important;
}
.sectionfive h1 span {
  color: #01aafc!important;
}
.sectionpastor {
  width: 100%;
  float: left;
  position: relative;
  padding: 52px 0px 62px 0px;
  overflow: hidden;
}
.sectionpastor h1 {
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000;
  text-align: left;
}
.sectionpastor h1 p{
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000;
  text-align: left;
}
.sectionpastor p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
}

.sectionpastor img {
  border-radius: 100%;
  width: 197px;
  object-fit: cover;
  object-position: center top;
  box-shadow: 2px 3px 7px #bbbbbb;
}
.selectionemptsss {
  background: #01aafc;
  color: #fff;
  text-shadow: none;
  border-radius: 17px;
  width: 8px;
  height: 100%;
  margin: 0 0 0 12px;
}
.sectionpastor h1 span {
  color: #01aafc;
}
/* --------------------------------------------------------------------------------------------- */
.innercircle {
  background-color: #2EB872;
  transform: scale(0.8);
  left: -17px;
  top: 21px;
}
.circle {
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 500%;
  transform-origin: 50% 50%;
}
.outcircle {
  margin-left: calc(36% - 100px);
  box-shadow: inset 0 0 0px 2px #2EB872;
  height: 18px;
  width: 18px;
  top: 17px;
}
.hide {
  display: none;
}

.animation1 {
  z-index: 1;
  background-color: #FF5D6E;
  animation: scale 2s infinite;
}

.animation2 {
  box-shadow: inset 0 0 0px 2px #FF5D6E;
  z-index: 1;
}

.animation3 {
  z-index: 1;
  background-color: #FF5D6E;
  animation: scale 2s infinite;
}

.animation4 {
  z-index: 0;
  animation: scale 2s infinite;
  animation-delay: 1s;
}

.animation31 {
  animation: color 2s infinite;
}

#x {
  animation: colora 2s infinite;
}

#y {
  animation: colorb 2s infinite;
  animation-delay: 1s;
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes color {
  0% {
    box-shadow: inset 0 0 0px 2px #2EB872;
  }
  50% {
    box-shadow: inset 0 0 0px 2px #FF5D6E;
  }
  100% {
    box-shadow: inset 0 0 0px 2px #2EB872;
  }
}
@keyframes colora {
  0% {
    background-color: #2EB872;
    transform: scale(0);
  }
  50% {
    background-color: #FF5D6E;
    transform: scale(0.8);
  }
  100% {
    background-color: #2EB872;
    transform: scale(0);
  }
}
@keyframes colorb {
  0% {
    background-color: #FF5D6E;
    transform: scale(0);
  }
  50% {
    background-color: #2EB872;
    transform: scale(0.8);
  }
  100% {
    background-color: #FF5D6E;
    transform: scale(0);
  }
}
.button1 {
  margin-left: calc(-25% - 100px);
}

.button3 {
  position: relative;
}
.sqsss {
  width: 77px;
  height: 77px;
  border-radius: 100%;
  display: block;
  object-fit: cover;
  object-position: center top;
  margin: 0 auto 10px;
  border: 1px solid var(--lightgrey-color);
  box-shadow: 3px 3px 10px #b1ac9e;
}

/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

.headerlefttimes2:hover .headerlefttimes {
    display: block;
}
.headerlefttimes {
  background-color: white;
  box-shadow: 0px 2px 7px #6e2a007a;
  position: absolute;
  width: 260px;
  left: 0;
  display: none;
  z-index: 999;
  padding: 17px;
  cursor: pointer;
}
.headerlefttimes4{
  cursor: pointer;
}
p.headerlefttimes3 {
  color: black;
  width: 100%;
  text-align: left;
  line-height: 27px;
  padding: 0;
  font-size: 17px;
}
p.headerlefttimes3 span{
  color: #01aafc;
}
.header-middle .headerlefttimes4 {
  margin: 0;
  padding: 0;
  line-height: 50px;
  border-right: 0px solid rgba(255, 255, 255, 0.3);
  border-left: 0px solid rgba(255, 255, 255, 0.3);
  text-align: left;
  font-size: 18px;
  display: block;
}
.mbq{
  margin-bottom: 17px;
}
span.mbq2 {
  float: right;
  margin-right: 7px;
}
.servicetime ul.submenuss {
  width: 297px;
}
.mqs2 {
  border-bottom: 1px solid lavender;
  margin-bottom: 10px!important;
  width: 100%;
  float: left;
  padding-bottom: 10px;
}
.submenuss .mqs2:last-child{
  margin-bottom: 0px;
}
.sectionqqmmsss {
  padding: 37px 17px 37px 17px;
  width: 100%;
  float: left;
  border-radius: 17px;
  box-shadow: 3px 7px 17px #9898984a;
  background-color: white;
}
.videos .sectionqqmmsss H1 {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 37px;
  color: #000000;
  text-align: center;
}
.videos .buttonlink {
  float: none;
}
.aboutpages h1 span{
  color: #01aafc!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.aboutpages2 h1 span {
  color:#01aafc!important;
}
span{
  font-family: "PT Serif",serif!important;
}
.aboutpages p span{ color: #635d5d!important;background-color: rgb(255 255 255 / 0%)!important;
  text-align: center!important;font-size: 18px!important;line-height: 37px!important;}
.aboutpages2 strong {
    color: #01aafc;
    font-size: 23px;
    border-bottom: 2px solid;
}
.servicespage{
  margin-top:54px;
}
.servicespagePreviews{
  margin-top:54px;
  margin-bottom: 37px;
}
.servicespagePreviews img {
  width: auto;
  height: auto;
  max-height: 397px;
  object-fit: cover;
  object-position: center;
  margin-bottom: 60px;
}
.servicespagePreviews h3 {
  font-size: 37px;
  font-weight: 600;
  letter-spacing: .7px;
  margin-bottom: 37px;
  text-align: center;
  margin-top: 37px;
}
.servicespagePreviews p{
  font-size: 17px;
  line-height: 33px;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  overflow: visible;
  text-overflow: unset;
  display: block;
  height: auto;
}
.servicespage img {
  width: 304px;
  height: 277px;
  object-fit: none;
  object-position: center top;
  margin-bottom: 37px;
}
.servicespage h3 {
  font-size: 22px;
  letter-spacing: .7px;
  text-align: left;
  line-height: 37px;
  margin-bottom: 17px;
  height: 40px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.servicespage p.sectionseven2 {
    text-align: left;
    font-size: 17px;
    line-height: 33px;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    height: 190px;
    margin-bottom: 0;
}
.pastorspages {
  width: 100%;
  float: left;
  position: relative;
  padding: 54px 0px 36px 0px;
}
.pastorspages h1.aboutpagesqqq {
  font-size: 47px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000000!important;
  background-color: rgb(247 244 239 / 0%)!important;
}
.pastorspages h1.aboutpagesqqq p{
  font-size: 47px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000000!important;
  background-color: rgb(247 244 239 / 0%)!important;
}
.pastorspages h1.aboutpagesqqq p span{
  font-size: 47px!important;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000000!important;
  background-color: rgb(247 244 239 / 0%)!important;
}
.pastorspages.pastorspages2 h1.aboutpagesqqq {
  margin-bottom: 37px;
}
.pastorspages.pastorspages2 img.attachmentsss {
  margin-top: 17px;
  border-radius: 10px;
  box-shadow: 4px 6px 10px #adaeb1;
  max-width: 90%;
  margin-bottom: 27px;
}
.pastorspages2 .aboutpagesqqq2 p {
    color: #635d5d!important;
    background-color: rgb(255 255 255 / 0%)!important;
    text-align: left!important;
    font-size: 18px!important;
    line-height: 37px;
}
.pastorspages iframe#ytplayer {
  max-width: 67%;
  height: 437px;
  width: 100%;
  margin: 0 auto 52px;
  display: block;
  position: relative;
  box-shadow: 7px 7px 17px -15px, 0 0 7px -17px;
  color: #000;
  border-radius: 17px;
  border: 7px solid #fff;
}
.pastorspagesqqq{
  min-height: 77vh;
}
.servicespagelist{
  margin-bottom: 17px;
    border: 1px solid #dadada;
    padding: 27px;
    width: 100%;
    float: left;
    overflow: hidden;
}
.sectionseven2 span{
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 17px!important;
}
.sectionseven2 a span{
  color: #007bff!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 17px!important;
}
.sectiontwo .block-ellipsis p span{
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 16px!important;
}
.previewevents p{
  text-align: left;
}
.previewevents2 p{
  text-align:center;
}
.previewevents p span{
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
  text-align: left;
}
.previewevents a span{
  color: #007bff!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 18px!important;
  line-height: 37px;
  text-align: left;
}
.previewevents p h2 {
  margin-bottom: 13px;
}
.events p h2 {
  text-align: left;
  font-size: 18px;
  margin-bottom: 10px;
  color: #635d5d!important;
}
.videos p span{
  text-align: left;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 18px!important;
  line-height: 37px;
  height: 77px;
  overflow: hidden;
  margin-bottom: 0;
  color: #635d5d!important;
}
.sectionpastor h1 p span{
  font-size: 42px!important;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000!important;
  text-align: left;
}
.sectionpastor p span{
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.sectionpastor a span{
  color: #007bff!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 18px!important;
  line-height: 37px;
  text-align: left;
}
.sectionpastor li{
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.sectionpastor li span{
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.sectionpastor li a span{
  color: #007bff!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 18px!important;
  line-height: 37px;
  text-align: left;
}
.Sectionone li span{
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.sectionfour p span{
  color: white!important;
  font-size: 17px!important;
  line-height: 37px;
  text-align: left;
  background-color: rgb(255 255 255 / 0%)!important;
}
.colmd12nesss h1, .colmd12nesss h2, .colmd12nesss h3, .colmd12nesss h4, .colmd12nesss h5, .colmd12nesss h6 {
  color: #fff;
  font-size: 17px;
  padding: 0 37px;
  letter-spacing: .7px;
  line-height: 37px;
  text-align: left;
  font-family: 'PT Serif', serif!important;
  font-weight: 400;
  
}
.colmd12nesss h1 span, .colmd12nesss h2 span, .colmd12nesss h3 span, .colmd12nesss h4 span, .colmd12nesss h5 span, .colmd12nesss h6 span {
  color: white!important;
  font-size: 17px!important;
  line-height: 37px;
  text-align: left;
  background-color: rgb(255 255 255 / 0%)!important;
  font-weight: 400;
}
.sectionnine h1, .sectionnine h2 , .sectionnine h3 , .sectionnine h4 , .sectionnine h5 , .sectionnine h6  { 
  color: #000!important;

}
.sectionnine h1 p {
  color: #000!important;
}
.sectionnine h2 p, .sectionnine h3 p, .sectionnine h4 p, .sectionnine h5 p, .sectionnine h6 p { 
  font-weight: 600;
  letter-spacing: 0.7px;
  text-align: left;
  font-size: 42px;
  margin-bottom: 27px;
  line-height: 67px;
  color: #000!important;
}
.sectionnine h2 p span, .sectionnine h3 p span, .sectionnine h4 p span, .sectionnine h5 p span, .sectionnine h6 p span {
  background-color: rgb(255 255 255 / 0%);
  color: #01aafc!important;
}
.sectionnine p {
  color: #635d5d!important;
}
.sectionnine p span{
  color: #635d5d!important;
}
.sectionnine p strong{
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  font-weight: 400;
  color: #635d5d!important;
}
.sectionninedesc h1, .sectionninedesc h2, .sectionninedesc h3,  .sectionninedesc h4, .sectionninedesc h5, .sectionninedesc h6 {
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  font-weight: 400;
  color: #635d5d!important;
}
.sectionninedesc h1 span, .sectionninedesc h2 span, .sectionninedesc h3 span,  .sectionninedesc h4 span, .sectionninedesc h5 span, .sectionninedesc h6 span {
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  font-weight: 400;
  color: #635d5d!important;
}
.Sectiononetitle h1, .Sectiononetitle h2, .Sectiononetitle h3,  .Sectiononetitle h4, .Sectiononetitle h5, .Sectiononetitle h6 {
  font-size: 42px;
  font-weight: 600;
  letter-spacing: .7px;
  margin-bottom: 62px;
  text-align: center!important;
}
.Sectiononetitle h1 span , .Sectiononetitle h2 span, .Sectiononetitle h3 span,  .Sectiononetitle h4 span, .Sectiononetitle h5 span, .Sectiononetitle h6 span{
  color: #01aafc!important;
  font-size: 42px!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.Sectiononetitle2 p{
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
}
.Sectiononetitle2 h1, .Sectiononetitle2 h2, .Sectiononetitle2 h3,  .Sectiononetitle2 h4, .Sectiononetitle2 h5, .Sectiononetitle2 h6{
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
}
.Sectiononetitle2 h1 span , .Sectiononetitle2 h2 span, .Sectiononetitle2 h3 span,  .Sectiononetitle2 h4 span, .Sectiononetitle2 h5 span, .Sectiononetitle2 h6 span{
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
}
.sectionpastor2 p, .sectionpastor2 h1, .sectionpastor2 h2, .sectionpastor2 h3, .sectionpastor2 h4, .sectionpastor2 h5, .sectionpastor2 h6{
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
}
.sectionpastor2 p span, .sectionpastor2 h1 span, .sectionpastor2 h2 span, .sectionpastor2 h3 span, .sectionpastor2 h4 span, .sectionpastor2 h5 span, .sectionpastor2 h6 span{
  text-align: left;
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
}
.sectionpastor2 p a span, .sectionpastor2 h1 a span, .sectionpastor2 h2 a span, .sectionpastor2 h3 a span, .sectionpastor2 h4 a span, .sectionpastor2 h5 a span, .sectionpastor2 h6 a span{
  color: #007bff!important;
}
.sectionpastor3 h1, .sectionpastor3 h2, .sectionpastor3 h3, .sectionpastor3 h4, .sectionpastor3 h5, .sectionpastor3 h6{
  font-size: 42px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000;
  text-align: left;
  line-height: 60px;
}
.sectionpastor3 h1 p, .sectionpastor3 h2 p, .sectionpastor3 h3 p, .sectionpastor3 h4 p, .sectionpastor3 h5 p, .sectionpastor3 h6 p{
  font-size: 42px!important;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000!important;
  text-align: left;
  line-height: 60px;
}
.sectionpastor3 h1 p span, .sectionpastor3 h2 p span, .sectionpastor3 h3 p span, .sectionpastor3 h4 p span, .sectionpastor3 h5 p span , .sectionpastor3 h6 p span{
  font-size: 42px!important;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  color: #000!important;
  text-align: left;
  line-height: 60px;
}
.sectionpastor3 p{
  line-height: 60px;
}
.sectionseven2 h1, .sectionseven2 h2, .sectionseven2 h3, .sectionseven2 h4, .sectionseven2 h5, .sectionseven2  h6 {
  font-size: 27px;
  font-weight: 600;
  letter-spacing: 0.7px;
  margin-bottom: 17px;
  text-align: left!important;
  margin-top: 37px;
  color: #5d5858!important;
}
.servicespage .sectionseven2 h1, .servicespage .sectionseven2 h2, .servicespage .sectionseven2 h3, .servicespage .sectionseven2 h4, .servicespage .sectionseven2 h5, .servicespage .sectionseven2  h6 {
  margin-top: 0px!important;
}
.servicespagePreviews .servicespagelist  p span{
  font-size: 17px!important;
  line-height: 33px;
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  overflow: visible;
  text-overflow: unset;
  display: block;
  height: auto;
  color: #635d5d!important;
  font-family: 'PT Serif', serif!important;
}
.sectionseven2 span, .sectionseven2 h1 span, .sectionseven2 h2 span, .sectionseven2 h3 span, .sectionseven2 h4 span, .sectionseven2 h5 span, .sectionseven2  h6 span {
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 17px!important;
}
.aboutpagesqqq3 p{
  text-align: center!important;
}
.aboutpagesqqq4 h1, .aboutpagesqqq4 h2, .aboutpagesqqq4 h3, .aboutpagesqqq4 h4, .aboutpagesqqq4 h5, .aboutpagesqqq4  h6 {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  color: #635d5d!important;
}
.aboutpagesqqq4 span, .aboutpagesqqq4 h1 span, .aboutpagesqqq4 h2 span, .aboutpagesqqq4 h3 span, .aboutpagesqqq4 h4 span, .aboutpagesqqq4 h5 span, .aboutpagesqqq4  h6 span {
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 18px!important;
}
.aboutpagesqqq5 p{
  text-align: center!important;
}
.aboutpagesqqq6 h1, .aboutpagesqqq6 h2, .aboutpagesqqq6 h3, .aboutpagesqqq6 h4, .aboutpagesqqq6 h5, .aboutpagesqqq4  h6 {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  color: #ffffff!important;
  text-align: center!important;
}
.aboutpagesqqq6 span, .aboutpagesqqq6 h1 span, .aboutpagesqqq6 h2 span, .aboutpagesqqq6 h3 span, .aboutpagesqqq6 h4 span, .aboutpagesqqq6 h5 span, .aboutpagesqqq6  h6 span {
  color: #ffffff!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 18px!important;
  text-align: center!important;
}
.aboutpages3 p{
  text-align: center!important;
}
.aboutpagesqqq5 h1, .aboutpagesqqq5 h2, .aboutpagesqqq5 h3, .aboutpagesqqq5 h4, .aboutpagesqqq5 h5, .aboutpagesqqq5  h6 {
    color: #ffffff!important;
    font-size: 43px!important;
    font-weight: 700;
    letter-spacing: 0.7px;
    margin-bottom: 27px;
    text-align: center;
    color: #ffffff;
    line-height: 60px;
}
.aboutpagesqqq5 span{
    color: #ffffff!important;
    font-size: 43px!important;
    font-weight: 700;
    letter-spacing: 0.7px;
    margin-bottom: 27px;
    text-align: center;
    color: #ffffff;
    line-height: 60px;
}
.aboutpages h1 p{
  text-align: center!important;
}
.aboutpagesqqq7 h1, .aboutpagesqqq7 h2, .aboutpagesqqq7 h3, .aboutpagesqqq7 h4, .aboutpagesqqq7 h5, .aboutpagesqqq7 h6 {
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  line-height: 60px!important;
}
.aboutpagesqqq7 h1 p, .aboutpagesqqq7 h2 p, .aboutpagesqqq7 h3 p, .aboutpagesqqq7 h4 p, .aboutpagesqqq7 h5 p, .aboutpagesqqq7 h6 p {
  font-size: 43px;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  line-height: 60px!important;
}
.aboutpagesqqq7 h1 span, .aboutpagesqqq7 h2 span, .aboutpagesqqq7 h3 span, .aboutpagesqqq7 h4 span, .aboutpagesqqq7 h5 span, .aboutpagesqqq7 h6 span {
  color: #01aafc!important;
  background-color: rgb(255 255 255 / 0%)!important;
  font-size: 43px!important;
  font-weight: 700;
  letter-spacing: 0.7px;
  margin-bottom: 27px;
  line-height: 60px!important;
}
.aboutpagesqqq8 p, .aboutpagesqqq8 h1, .aboutpagesqqq8 h2, .aboutpagesqqq8 h3, .aboutpagesqqq8 h4, .aboutpagesqqq8 h5, .aboutpagesqqq8 h6 {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  color: #635d5d!important;
}
.aboutpagesqqq8 p span, .aboutpagesqqq8 h1 span, .aboutpagesqqq8 h2 span, .aboutpagesqqq8 h3 span, .aboutpagesqqq8 h4 span, .aboutpagesqqq8 h5 span, .aboutpagesqqq8 h6 span{
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  color: #635d5d!important;
}
.sectioneighttwo p, .sectioneighttwo p h1, .sectioneighttwo p h2, .sectioneighttwo p h3, .sectioneighttwo p h4, .sectioneighttwo p h5, .sectioneighttwo p h6, .sectioneighttwo p h1 p, .sectioneighttwo p h2 p, .sectioneighttwo p h3 p, .sectioneighttwo p h4 p, .sectioneighttwo p h5 p, .sectioneighttwo p h6 p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  height: 77px;
  overflow: hidden;
  margin-bottom: 0;
  color: #635d5d;
}
.sectioneighttwosss p, .sectioneighttwosss p h1, .sectioneighttwosss p h2, .sectioneighttwosss p h3, .sectioneighttwosss p h4, .sectioneighttwosss p h5, .sectioneighttwosss p h6, .sectioneighttwosss p h1 p, .sectioneighttwosss p h2 p, .sectioneighttwosss p h3 p, .sectioneighttwosss p h4 p, .sectioneighttwosss p h5 p, .sectioneighttwosss p h6 p {
  text-align: left;
  font-size: 18px;
  line-height: 37px;
  height: 77px;
  overflow: hidden;
  margin-bottom: 0;
  color: #635d5d;
}
.sectioneighttwo p span{
  font-size: 18px!important;
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.sectioneighttwosss p span{
  font-size: 18px!important;
  color: #635d5d!important;
  background-color: rgb(255 255 255 / 0%)!important;
}
.mnzxsss, .mnzxsss h1, .mnzxsss h2, .mnzxsss h3, .mnzxsss h4, .mnzxsss h5, .mnzxsss h6{
  font-size: 18px;
  line-height: 37px;
  color: #635d5d; 
}
.servicespage .table-header p, .servicespage .table-header p span, .servicespage .table-header h1, .servicespage .table-header h1 span, .servicespage .table-header h2, .servicespage .table-header h2 span, .servicespage .table-header h3, .servicespage .table-header h3 span, .servicespage .table-header h4, .servicespage .table-header h4 span, .servicespage .table-header h5, .servicespage .table-header h5 span, .servicespage .table-header h6, .servicespage .table-header h6 span{
  font-size: 18px!important;
  line-height: 37px;
  color: #635d5d!important;
  text-align: left!important;
}
span.ltx-hex-inner2 {
  position: absolute;
  right: 43px;
  width: 100px;
  height: 40px;
  top: 5px;
  vertical-align: middle;
  text-align: center;
  line-height: 38px;
  color: #01aafc; 
}
/* --------------------------------------------------------------------------------------------- */
@media(max-width:767px){
  .pastorspages iframe#ytplayer {
    max-width: 100%;
    height: 300px;
  }
  .servicespagelist {
    padding: 10px;
}
  .pastorspages h1.aboutpagesqqq, .pastorspages h1.aboutpagesqqq p {
    font-size: 40px;
  }
  .sectiontwo .block-ellipsis p {
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    overflow: hidden;
    text-overflow: unset;
    display: block;
  }
  .Sectionone {padding: 27px 0 62px;}
  .sectiontwo h6 {margin-bottom: 7px;}
  .sectionnine img { margin-top: 17px;}
  .banners2 h1 {font-size: 27px;line-height: 37px;}
  .banners2 {top: 10%;}
  .banners2 p {font-size: 16px;margin: 3px 0px 0px 0px;}
  .banners2 h6 {padding: 10px 27px 10px 27px;font-size: 14px;margin: 3px 0 0 0;}
  .Sectionone h1 {font-size: 33px;line-height: 47px;}
  .creative_about_img .dot {display: none;}
  .creative_about_img {margin-bottom: 37px;}
  .sectioneight H1 {font-size: 33px;}
  .sectioneighttwo iframe#ytplayer {width: 100%;}
  .events h2 {margin-top: 27px;line-height: 37px;}
  .resss{display: none;}
  .sectionfive img {margin-top: 0px;margin-bottom: 47px;}
  .sectionfive {padding: 60px 0px 17px 0px;}
  .swmsc_half_bg_container.swmsc_half_bg_right.col-md-6 {display: none;}
  .swmsc_half_bg2 {padding: 60px 17px 77px 17px;}
  .swmsc_half_bg2 {padding: 60px 17px 60px 17px;}
  .sectionnine {padding: 47px 0px 47px 0px;}
  img.servicesss {margin-bottom: 27px;}
  .sectiontwo7 .servicesss2:hover img.servicesss {position: relative;left: unset;right: unset;margin: 0 auto 23px;top: unset;}
  .sectiontwo7 .servicesss2:hover h3 {top: unset;position: relative;left: unset;right: unset;font-size: 23px;}
  .sectiontwo7 .servicesss2 h3 {top: unset;position: relative;left: unset;right: unset;font-size: 23px;margin-bottom: 27px;opacity: 1;}
  .sectiontwo7 {padding: 60px 0px 47px 0px;}
  .sectionseven {padding: 60px 0px 47px 0px;}
  .widget-flickr2 .col-md-3 {width: 23.333%;}
  div#Footer h1 {margin-bottom: 27px;}
  div#Footer {padding: 60px 0px 60px 0px;}
  .footersss2 p {font-size: 15px;}
  .aboutpages2qqq {padding: 27px;}
  .aboutpages2 h1 {font-size: 37px; line-height: 47px;}
  .breadcrumps {padding-top: 47px;padding-bottom: 47px;}
  .aboutpages4 img {margin-top: 37px;margin-bottom: 37px;}
  .prayerreqsss .col-md-1{display: none;}
  .prayerreqsss form.login-forms {padding: 27px;}
  .sectiontwo.resss2  {padding: 52px 0px 36px 0px;}
  .videos .sectioneighttwosss {padding: 0;}
  .videos iframe#ytplayer {width: 100%;}
  .contactforms h1 {font-size: 37px;}
  .contactforms2 ul li a {padding: 0 17px 0 0;}
  .videospreviews iframe#ytplayer {height: 277px;}
  .feedspopups {padding: 10px 4px;max-width: 100%;min-width: 100%;}
  .sec-title {margin: 27px 0 0 0;}
  .innercircle {left: -17px;}
  ul.submenuss {padding-top: 10px;}
  .servicetime .submenuss .col-md-6 {width: 50%;padding: 0;}
  
  .navBar ul.menuq2 {top: -147px;}
  .res5c.newresnnn{display: none!important;}
  .watchsss.newresnnn{display: none!important;}
.newresss{display: block!important;margin: 0 auto;}
.innercircle {opacity: 0;}
.navBar .logo img {max-height: 90px;}
.newresss a{color: #01aafc;text-align: center;}
.sectionpastor h1 p {margin-bottom: 17px;margin-top: 17px;text-align: center;}
.sectionpastor2 p, .sectionpastor2 h1, .sectionpastor2 h2, .sectionpastor2 h3, .sectionpastor2 h4, .sectionpastor2 h5, .sectionpastor2 h6 {text-align: center;}
.Sectiononetitle h1 span, .Sectiononetitle h2 span, .Sectiononetitle h3 span, .Sectiononetitle h4 span, .Sectiononetitle h5 span, .Sectiononetitle h6 span {font-size: 33px!important;}
.Sectiononetitle h1, .Sectiononetitle h2, .Sectiononetitle h3, .Sectiononetitle h4, .Sectiononetitle h5, .Sectiononetitle h6 {font-size: 33px;}
.sectionfive h1 p {font-size: 33px;}
.res5c.newresss{float: left!important}
.res5c.newresss svg{height: 23px;}
.block-ellipsis {
  height: auto;
  overflow: visible;
  margin-bottom: 10px;
}
}

@media (min-width: 576px) and (max-width: 767px) { 
  .header-top-inner .dgt-social ul li a {min-width: 30px!important}
  .banners2 p {font-size: 18px!important;margin: 17px 0px 17px 0px!important;}
  .banners2 {top: 17%!important;}

}
@media (min-width: 768px) and (max-width: 991px) { 
  .banners2 {top: 7%;}
  .swmsc_half_bg2 {padding: 47px 27px 52px 37px;}
  img.servicesss {width: 137px;height: 137px;}
  .sectionseven img {height: 160px;margin-bottom: 17px;}
  .sectionthree .dgt-team-item-inner .dgt-social {margin: 0 17px;}
  .blogsss img {height: 197px;margin-bottom: 17px;}
  .videos iframe#ytplayer {width: 100%;height: 237px;}
  .videospreviews iframe#ytplayer {height: 397px;}
  .dgt-contact-info {padding: 0 17px;}
  .res5c2 {
    margin: 40px auto 0!important;
}

}

@media (min-width: 768px) {
  .newresss{display: none!important;}
  .res5c.newresss{display: none!important;}
  
  .res5c2{
    display: table!important;
    float: none;
    background-color: #01aafc;
    color: #fff;
    padding: 10px 17px!important;
    border-radius: 5px;
    margin: 10px auto;
    cursor: pointer;
  }
  

}
@media (min-width: 991px) { 
  .menu-item.servicetime{display: none;}
  .menu-item:hover .submenuss{display: block;}
  .res5c2.newresnnn{display: none!important;}
  .mnqwsssxxx .col-md-2 {flex: 0 0 19.666667%;max-width: 19.666667%;}
}

@media (max-width: 991px) { 
 .blogssspreviesss.blogsss h3{
  -webkit-line-clamp: unset;
  -webkit-box-orient: unset;
  overflow: hidden;
  text-overflow: unset;
  display: block;
 }
.header-top-inner .col-md-8 {flex: 0 0 100%; max-width: 100%;}
.submenussress{display: block!important;}
.submenussresstime{display: block!important;}
.submenussressgallery{display: block!important;}
.submenussressAbout{display: block!important;}
.headerlefttimes2{display: none;}
ul.submenuss {right: 0;margin: 0 auto;top: 37px;z-index: 10000;}
.res5c {display: inline-block!important;float: none;color: #fff;padding: 3px 7px;border-radius: 5px;margin: 10px auto;cursor: pointer;}
.navBar ul.menuq2 {display: block!important;position: absolute;left: 0;z-index: 7;background-color:#01aafc; right: 0;padding-bottom: 17px;}
ul.main-menu {display: none;position: relative;list-style: none;margin: 4px auto 0;padding: 0;
  float: right;z-index: 7;}
  .header-top-inner p {margin: 10px 0 7px 0;padding: 0 10px;line-height: 27px;border-right: 0px solid rgba(255, 255, 255, 0.3);border-left: 0px solid rgba(255, 255, 255, 0.3);}
  .header-top-inner .dgt-social ul li a {border-left: 0px solid rgba(255, 255, 255, 0.3);min-width: 37px;padding: 0 3px;}
  .navBar .logo img {float: none;margin-top: 7px;}
  .menu-item a {font-size: 20px;line-height: 27px; color: #ffffff;margin: 20px 10px;}
  ul.main-menu {margin: 0px auto 0!important;}
  .sectionthree .dgt-social{display: none;}



}


@media (min-width: 991px) and (max-width: 1199px) { 
  .header-top-inner p {padding: 0 4px;font-size: 15px;}
  .header-top-inner p svg {margin-right: 3px;width: 13px!important;}
  .header-top-inner .col-md-7{padding: 0px;}
  .menu-item a {font-size: 16px; margin: 37px 3px;}
  .swmsc_half_bg2 {padding: 62px 27px 62px 60px;}
  img.servicesss {width: 147px;height: 147px;}
  .sectionseven img {height: 217px;}
  .p_absoulte2 {top: 10%;}
  .sectionthree .dgt-team-item-inner .dgt-social {margin: 0 60px;}
  .sectiontwo {padding: 52px 0px 37px 0px;}
  .blogsss img {height: 217px;}


}








/* --------------------------------------------------------------------------------------------- */
.godgrace-go-categories {
  float: right;
  width: 100%;
  text-align: right;
  margin-bottom: 17px;
  color: #084ff9;
}
.godgrace-go-categories .buttonlink {
  margin: 0px 0 0 0;
  float: right;
}
h4.headerlefttimes3 {
  float: left;
  font-size: 20px;
  color: #000000;
  text-transform: capitalize;
  border-bottom: 1px solid #0443e3;
  padding-bottom: 4px;
  letter-spacing: 0.7px;
}
p.headerlefttimes3 span {
  color: #000000;
  margin-bottom: 10px;
}
.ssqqmmnxxx{
  color: #01aafc!important;
}
.evenasss{
  position: relative;
    font-size: 16px;
    font-weight: 400;
    margin-right: 10px;
    padding: 3px 10px;
    display: inline-block;
    line-height: 37px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    color: #555;
    background-color: #ffffff;
    border: 0px;
    box-shadow: -4px -4px 10px 0px #ffffff, -4px -4px 5px 0px #ffffff, 4px 4px 10px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #ffffff, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #ffffff, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
    -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
    -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
    -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
    -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
}
.previewevents .events {
  background-image: none!important;
  background-color: #fff!important;
}
.headerlefttimes3 b {
  font-weight: 400;
  color: #01aafc;
}
.orderslistsss {
  float: right;
  display: block;
  text-align: right;
  background-color: #e4e0da;
  border-radius: 3px;
  border: 0;
  color: #312c2c!important;
  font-size: 14px;
  padding: 7px 17px;
  font-weight: 500;
  font-family: Open Sans,Arial,sans-serif;
  margin-bottom: 27px;
  cursor: pointer;
  outline: none;
}
.godgrace_breadcrumbs{
  display: none;
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
