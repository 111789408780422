
.app-sidebar {
       position: fixed;
       top: 0;
       bottom: 0;
       left: 0;
       margin-top: 4.7rem;
       width: 230px;
       max-height: 100%;
       z-index: 1;
       background: #fff !important;
       box-shadow: 3px 0 10px 0 rgba(183,192,206,.2);
       overflow-y: scroll;
       overflow-x: hidden !important;
       overflow-anchor: none;
       -ms-overflow-style: none;
       touch-action: auto;
       -ms-touch-action: auto;
}
.simplebar-wrapper ul{
       margin-bottom: 0;
       padding-bottom: 40px;
       padding: 0;
}  
.simplebar-wrapper ul li {
       line-height: 27px;
       height: 57px;
       border-bottom: 1px solid rgba(67, 87, 133, .2);
       padding: 17px 20px 7px 37px;
       display: table;
       width: 100%;
       float: left;
   }
.simplebar-wrapper ul li a {
       position: relative;
       color: #031938;
       text-align: left;
       float: left;
       font-size: 16px;
   }
.simplebar-wrapper svg {
       width: 17px;
       margin-right: 10px;
       color: #686b68;
   }
 .app-header{
       position: fixed;
       width: 100%;
       margin: 0 auto;
       z-index: 1;
       left: 0;
       right: 0;
       top: 0;
       display: flex;
       padding: 0.75rem 0;
       height: auto;
       box-shadow: 3px 0 10px 0 rgba(183,192,206,.2);
       background: #fff;
}
@media (min-width: 992px){ 
   .app-header {
       z-index: 999 !important;
   }
  
}
.app-header .adminlogo {
       color: inherit;
       margin-right: 1rem;
       font-size: 1.25rem;
       white-space: nowrap;
       font-weight: 600;
       padding: 0;
       transition: .3s opacity;
       line-height: 2.9rem;
       min-width: 220px;
       text-align: center;
}
.adminlogo img {
       height: 2.5rem;
       line-height: 2rem;
       vertical-align: bottom;
       margin-right: .5rem;
       width: auto;
}
@media (min-width: 992px){
       .app-content {
       margin-left: 230px;
       }
}
.app-content {
       min-height: calc(100vh - 50px);
       margin-top: 70px;
       margin-bottom: 0 !important;
       transition: margin-left 0.3s ease;
       overflow: hidden;
       background: #e8edf2;
}
.page-content {
       padding: 30px;
}
.adminnavright {
       margin-left: auto;
       padding: 0 1rem;
       display: flex;
   } 
.Adminbuttonlink {
       position: relative;
       font-size: 18px;
       font-weight: 400;
       padding: 6px 16px;
       margin-left: 17px;
       display: inline-block;
       line-height: 37px;
       border-radius: 4px;
       cursor: pointer;
       transition: all 0.3s ease-in;
       color: #555;
       background-color: #ffffff;
       border: 0px;
       box-shadow: -4px -4px 10px 0px #ffffff, -4px -4px 5px 0px #ffffff, 4px 4px 10px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #ffffff, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #ffffff, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
       -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
   }   
.Adminbuttonlink:hover {
       box-shadow: 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #ffffff, inset -4px -4px 5px 0px #ffffff, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
       -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       color: #803304;
       text-decoration: none;
   }
.Adminblogs .col-md-12{
       padding: 0px;
}
.Adminblogs2{
    width: 100%;
    float: left;
}
.Adminblogs2 img{
       width: 122px;
       height: 103px;
       object-fit: cover;
       object-position: center top;
       border: 1px solid #dadada;
       padding: 10px;
}
.Adminblogs3 {
       margin-bottom: 17px;
       padding: 17px 7px 17px 7px;
       width: 100%;
       float: left;
       background-color: white;
       box-shadow: 3px 0 10px 0 rgba(183,192,206,.2);
   }
.Adminblogs3 h3{
    text-align: left;
    font-size: 20px;
    letter-spacing: 0.7px;
}
.Adminbuttonlink2 {
       position: relative;
       font-size: 16px;
       font-weight: 400;
       margin-right: 10px;
       padding: 3px 10px;
       display: inline-block;
       line-height: 37px;
       border-radius: 4px;
       cursor: pointer;
       transition: all 0.3s ease-in;
       color: #555;
       background-color: #ffffff;
       border: 0px;
       box-shadow: -4px -4px 10px 0px #ffffff, -4px -4px 5px 0px #ffffff, 4px 4px 10px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #ffffff, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0px #ffffff, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
       -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
   }   
.Adminbuttonlink2:hover {
       box-shadow: 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 0px 0px 0px rgba(0, 0, 0, 0.1), inset -4px -4px 10px 0px #ffffff, inset -4px -4px 5px 0px #ffffff, inset 4px 4px 10px 0px rgba(0, 0, 0, 0.1), inset 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
       -webkit-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -moz-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -o-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       -ms-transition: box-shadow 0.3s cubic-bezier(0.79, 0.21, 0.06, 0.81);
       color: #803304;
       text-decoration: none;
   }
.Adminblogs4{
       float: right;
       margin-bottom: 17px;
       color: #7b2e00;
       font-weight: 700;
       letter-spacing: 0.7px;
   }
.adminblogssspreviesss .blogsss {
       padding: 0;
   }
.adminblogssspreviesss .blogsss .col-md-12{
       padding: 0;
   }
.adminblogssspreviesss .blogssspreviesss .sectionseven3 {
       padding: 17px 60px 37px 60px;
       background-color: white;
   }
.bookads2 {
       width: 100%;
       float: left;
       position: relative;
   }
.bookads2 form {
       background-color: white;
       padding: 37px;
   }
.bookads2 label {
       display: block;
       margin-bottom: 10px;
       float: left;
       margin-top: 17px;
       width: 100%;
       text-align: left;
       color: #000;
       font-size: 17px;
   }
span.adverts-form-required {
       color: #ff5800;
   }
.form-controlnnmmm2 {
       float: left;
   }
.public-DraftEditor-content {
       min-height: 300px;
       padding-left: 17px;
   }
.rdw-editor-main {
       background-color: #edf2f6;
       border: 1px solid #edf2f6;
       margin-bottom: 17px;
   }   
.Adminvideos{
       width: 100%;
       float: left;
       position: relative;
}
.Adminvideos .sectioneighttwosss {
       background-color: white;
       padding: 27px;
   }
.Adminvideos .videos iframe#ytplayer {
       width: 97%;
       height: 297px;
}
.Adminvideos .Adminbuttonlink2 {
       float: right;
}
.Adminvideos .videos {
       padding: 0px 0px 7px 0px;
}
.btn-primary {
       color: #fff;
       background-color: #7b2e00;
       border-color: #7b2e00;
   }
.react-date-picker {
       margin-left: 10%;
       height: 67px;
   }
.react-date-picker__wrapper {
       display: flex;
       flex-grow: 1;
       flex-shrink: 0;
       border: thin solid #7b2e00;
       height: 67px;
       padding: 7px 27px 7px 27px;
   }
.buttonlinkxxx{
       margin-left: 13%;
       color: #7b2e00;
       border: 1px solid;
       padding: 10px 17px 10px 10px;
       cursor: pointer;
}
.dgt-team-item-inner .dgt-team-avatar img {
       margin-top: 0;
   }
.Adminteams .sectionthree .dgt-team-item-inner {
       padding: 27px 17px 27px 17px;
       background-color: white;
   }
   p.error {
       color: red;
       letter-spacing: 0.7px;
   }
   
.Admingallerysss .gallerysss h3 {
       margin-bottom: 13px;
}
.Admingallerysss .ggaallsss {
       background-color: white;
       padding: 27px;
       margin-bottom: 27px;
   }
.Admingallerysss  .gallerysss img {
       margin-bottom: 0px;
   }
.Admingallerysss .gallerysss2 {
       margin-bottom: 0;
   }
.admingallerysss  .gallerysss {
       padding: 0;
   }
.Adminservisestimes .headerlefttimesqqq{
       background-color: white;
       padding: 27px;
       width: 100%;
       float: left;
}
.Adminvideoslives .videos {padding: 0;}
.Adminvideoslives .videospreviews iframe#ytplayer {
       width: 77%;
       height: 417px;
       margin: 17px auto 37px;
}
.Adminvideoslives .videos h1 {text-align: center;}
.Adminvideoslives .videos p {text-align: center;}
.Adminhomesss{
       background-color: white;
       width:100%;
       float: left;
}
.Adminhomesss .banner .Adminbuttonlink2 {
       float: right;
       margin-bottom: 17px;
       margin-top: 17px;
}
.Adminhomesss .BrainhubCarouselItem--active img {
       animation: unset;
       transition: unset;
}
.Adminhomesss2{
   text-align: left;
    float: left;
    color: #7b2e00;
    margin: 7px 0 0 0;
    letter-spacing: 0.7px;
    text-transform: capitalize;
}
.Sectionone .public-DraftEditor-content {
       min-height: auto;
       padding-left: 17px;
   }
.adminsectionfour .swmsc_half_bg2 {
       padding: 62px 13px 62px 77px;
   }
.Adminaboutpages {
       background-color: white;
       width: 100%;
       float: left;
       position: relative;
   }
.Adminhomesss .bannerimgsss {
       min-height: 360px;
   }
.Adminaboutpages .Adminbuttonlink2{
       color: black;
}
.Adminservicespage {
       background-color: white;
       width: 100%;
       float: left;
       min-height: 100vh;
   }
.Adminservicespage  .servicespage {
       min-height: auto;
   }
   .adminservicesorders {
       padding: 97px 0px 0px 0px;
       width: 100%;
       background-color: white;
       min-height: 60vh;
   }
 .editorTitle .public-DraftEditor-content {
       min-height: auto;
       padding-left: 17px;
   }
.admincontactforms{
       width: 100%;
       background-color: white;
       float: left;
       position: relative;
}
.admincontactforms .dgt-contact-info {
       padding: 0 17px;
   }

.textalignblocksss3 .rdw-editor-toolbar.toolbarClassName{
       display: none;
}
.textalignblocksss .rdw-fontsize-wrapper{display: none;}
.textalignblocksss .rdw-fontfamily-wrapper{display: none;}
.textalignblocksss .rdw-list-wrapper{display: none;}
.textalignblocksss .rdw-embedded-wrapper{display: none;}
.textalignblocksss .rdw-emoji-wrapper{display: none;}
.textalignblocksss .rdw-image-wrapper{display: none;}
.textalignblocksss .rdw-block-wrapper{display: none;}
.textalignblocksss .rdw-colorpicker-wrapper{display: none;}

.AdminpanelclassName .menu-item a {font-size: 16px;margin: 40px 6px;}
.adminpostersss{margin-top: 52px;}
.adminpostersss .sectionpastor {padding: 0px 0px 52px 0px;}
.textalignblocksss2 .rdw-inline-wrapper{display: none;}
.textalignblocksss2 .rdw-block-wrapper{display: none;}
.textalignblocksss2 .rdw-fontsize-wrapper{display: none;}
.textalignblocksss2 .rdw-fontfamily-wrapper{display: none;}
.textalignblocksss2 .rdw-list-wrapper{display: none;}
.textalignblocksss2 .rdw-text-align-wrapper{display: none;}
.textalignblocksss2 .rdw-link-wrapper{display: none;}
.textalignblocksss2 .rdw-embedded-wrapper{display: none;}
.textalignblocksss2 .rdw-emoji-wrapper{display: none;}
.textalignblocksss2 .rdw-image-wrapper{display: none;}


.textalignblocksss4 .rdw-fontsize-wrapper{display: none;}
.textalignblocksss4 .rdw-fontfamily-wrapper{display: none;}
.textalignblocksss4 .rdw-list-wrapper{display: none;}
.textalignblocksss4 .rdw-embedded-wrapper{display: none;}
.textalignblocksss4 .rdw-emoji-wrapper{display: none;}
.textalignblocksss4 .rdw-image-wrapper{display: none;}
.textalignblocksss4 .rdw-block-wrapper{display: none;}
.textalignblocksss4 .rdw-colorpicker-wrapper{display: none;}
.textalignblocksss4 .rdw-text-align-wrapper{display: none;}

.dropzone-wrapper {
       border: 2px dashed #91b0b3;
       color: #92b0b3;
       position: relative;
       height: 150px;
     }
     
     .dropzone-desc {
       position: absolute;
       margin: 0 auto;
       left: 0;
       right: 0;
       text-align: center;
       width: 40%;
       top: 50px;
       font-size: 16px;
     }
     
     .dropzone,
     .dropzone:focus {
       position: absolute;
       outline: none !important;
       width: 100%;
       height: 150px;
       cursor: pointer;
       opacity: 0;
     }
     
     .dropzone-wrapper:hover,
     .dropzone-wrapper.dragover {
       background: #ecf0f5;
     }
    .hrsss hr {
       margin-top: 1rem;
       margin-bottom: 3rem;
       border: 0;
       border-top: 1px solid rgba(0,0,0,.1);
       float: left;
       width: 100%;
   }
  .Adminservisestimes p.headerlefttimes3 span {
       color: #000000;
   }
   .Adminservisestimes  h4.headerlefttimes3 {
       color: #000000;
       float: left;
       border-bottom: 1px solid #01a3f9;
       padding-bottom: 10px;
       font-size: 20px;
   }
   .Adminservisestimes  p.headerlefttimes3 {
       float: left;
       margin-left: 37px;
       margin-top: 17px;
   }
   .Adminservisestimes  p.headerlefttimes3 span{
          margin-bottom: 17px;
   }
.AdminEvents .events {
       background-image: none!important;
       background-color: #fff!important;
     }
     table {
       font-family: arial, sans-serif;
       border-collapse: collapse;
       width: 100%;
       background-color: #fff!important;
     }
     td, th {
       border: 2px solid #e8edf2;
       text-align: left;
       padding: 13px;
   }
     tr:nth-child(even) {
       background-color: #ffffff;
   }
 .paster .aboutpages4 h1 p span, .sectionfive h1 span {
       color: #000000!important;
   }
   .paster .aboutpages4 h1 {
       text-align: center;
   }
  .paster .aboutpages4 h1 p {
       text-align: center;
   }
   .paster  .pasmsgimg{
       margin-top: 10px!important;
       max-width: 90%;
 }
 .paster iframe#ytplayer {
       max-width: 67%;
       height: 437px;
       width: 100%;
       margin: 0 auto 52px;
       display: block;
       position: relative;
       box-shadow: 7px 7px 17px -15px, 0 0 7px -17px;
       color: #000;
       border-radius: 17px;
       border: 7px solid #fff;
   }




























































































































